<template>
    <section class="hero is-bold is-primary is-fullheight">
        <slot></slot>
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">Sign In</h1>
                <form>
                    <div class="field">
                        <input v-model="email" class="input" type="email" placeholder="Email" autocomplete="username">
                    </div>

                    <div class="field">
                        <input v-model="password" class="input" type="password" placeholder="Password" autocomplete="current-password">
                    </div>

                    <div class="field">
                        <button  class="button" type="submit" @click="login">Sign in</button>
                    </div>
                    <div>                        
                        <router-link class="is-size-7" to="/resetpassword">Forgot password?</router-link>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import {authentication} from '../shared/authentication'

export default {
    name: "SignIn",
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        login: function(event) {
            event.preventDefault();
            authentication.signin(this.email, this.password, this.onLoginSuccess, this.onLoginError);
        },
        onLoginSuccess: function() {
            window.location.href = 'a/app.html';
        },
        onLoginError: function() {            
            alert("Login error");
        }
    }
}
</script>